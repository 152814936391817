import React, { RefObject, useState } from 'react';
import { Button, ColorPicker, Form, Input, message, Steps } from 'antd';
import ProForm, { ProFormColorPicker, ProFormInstance, ProFormSelect, ProFormText, ProFormTextArea, ProFormUploadButton } from '@ant-design/pro-form';
import { UploadOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import { CreateSiteDto, SessionInfoDto } from '../../services/Api';
import { GetLpuGroupResponse } from '../../libs/medflex';
import { Client } from '../../services/Client';
import PhoneInput from 'antd-phone-input';

const { Step } = Steps;

interface ClinicFormProps {
  sessionInfo?: SessionInfoDto;
  onSubmit?: (data: CreateSiteDto) => void;
  formRef?: RefObject<ProFormInstance>;
}

const ClinicForm = (props: ClinicFormProps) => {

  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [selectLpuGroup, setSelectLpuGroup] = useState<GetLpuGroupResponse>();
  const [data, setData] = useState<any>({});

  // FORM
  const steps = [
    {
      title: 'Адрес для вашего сайта',
      id:'selectDomain',
      content: (
        <>
          <ProForm.Item
            name={'slug'}
            rules={[
              { required: true, message: 'Введите адрес для сайта' },
              {
                pattern: /^(?!-)[A-Za-z0-9-]{5,63}(?<!-)$/,
                message: 'Адрес должен быть от 5 до 63 символов латинского алфавита и дефиса, не может начинаться или заканчиваться дефисом'
              }
            ]}
          >
            <Input
              addonBefore="https://"
              suffix=".clinilink.ru"/>
          </ProForm.Item>
        </>
      ),
    },
    {
      title: 'Основная информация',
      content: (
        <>
          <ProFormSelect
            preserve={true}
            name="branchesGroup"
            label="Выберите группу клиник"
            options={props.sessionInfo?.lpuGroups?.map(item => ({ label: item.name, value: item.id }))}

            placeholder="Выберите клинику"
            rules={[{ required: true, message: 'Выберите клинику' }]}
            onChange={(value) => {
              form.setFieldsValue({ branches: undefined });
              if (value) {
                const options = props.sessionInfo?.lpus.map(lpu => lpu.id);
                form.setFieldsValue({ branches: options });
                setSelectLpuGroup(props.sessionInfo?.lpuGroups.find(group => group.id == value));

              }
            }}
          />

          <ProFormSelect
            name={'branches'}
            fieldProps={{ mode: 'multiple' }}
            options={props.sessionInfo?.lpus.filter(lpu => lpu.lpu_group_id == selectLpuGroup?.id).map(lpu => ({ label: lpu.name, value: lpu.id }))}
          />


          <ProFormSelect
            name="clinicProfile"
            label="Профиль клиники"
            options={[
              { label: 'Стоматологическая клиника', value: 'dental' },
              { label: 'Косметологическая клиника', value: 'cosmetic' },
              { label: 'Многопрофильная клиника', value: 'multi' },
              { label: 'Монопрофильная клиника', value: 'mono' },
            ]}
            placeholder="Выберите профиль клиники"
            rules={[{ required: true, message: 'Выберите профиль клиники' }]}
          />


        </>
      ),
    },
    // {
    //   title: 'Категории услуг',
    //   content: (
    //     <ProFormTextArea
    //       name="categories"
    //       label="Категории услуг"
    //       placeholder="Укажите категории услуг, каждая с новой строки"
    //       rules={[{ required: true, message: 'Укажите категории услуг' }]}
    //     />
    //   ),
    // },
    {
      title: 'Информация о клинике',
      content: (
        <>
          <ProFormText
            name="clinicName"
            initialValue={selectLpuGroup?.name}
            label="Название клиники"
            placeholder="Введите название клиники"
            rules={[{ required: true, message: 'Введите название клиники' }]}

          />
          <ProFormTextArea
            fieldProps={{
              showCount: true,
              maxLength: 160,
            }}
            name="clinicDescription"
            label="Описание клиники"
            placeholder="Кратко опишите клинику"
            rules={[{ required: true, message: 'Опишите клинику', max: 160 }]}
          />

          <ProFormTextArea
            name="workingHours"
            label="График работы"
            placeholder="Введите график работы"
            rules={[{ required: true, message: 'Введите график работы' }]}
          />
          {/*<ProFormList*/}
          {/*  name="addresses"*/}
          {/*  label="Адреса приема"*/}
          {/*  i18nIsDynamicList={true}*/}
          {/*  deleteIconProps={{*/}
          {/*    tooltipText: 'Удалить',*/}
          {/*  }}*/}
          {/*  rules={[*/}
          {/*    {*/}
          {/*      validator: async (_, addresses) => {*/}
          {/*        if (!addresses || addresses.length === 0) {*/}
          {/*          return Promise.reject(new Error('Введите хотя бы один адрес'));*/}
          {/*        }*/}
          {/*        return Promise.resolve();*/}
          {/*      },*/}
          {/*    },*/}
          {/*  ]}*/}
          {/*  creatorButtonProps={{ position: 'bottom' }}*/}
          {/*>*/}
          {/*  <ProFormText*/}
          {/*    name="address"*/}
          {/*    placeholder="Введите адрес"*/}
          {/*    rules={[{ required: true, message: 'Введите адрес' }]}*/}
          {/*  />*/}
          {/*</ProFormList>*/}
          <ProFormText
            name="licenseNumber"
            label="Номер лицензии клиники"
            placeholder="Введите номер лицензии"
            rules={[{ required: true, message: 'Введите номер лицензии' }]}
          />
          <ProFormText
            name="inn"
            label="ИНН"
            placeholder="Введите ИНН"
            rules={[{ required: true, message: 'Введите ИНН' }]}
          />

          <ProForm.Item
            name="mainPhone"
            label="Основной контактный телефон"
            getValueFromEvent={(value) => {
             return '+'+value.countryCode+value.areaCode+value.phoneNumber;
            }}
            rules={[
              { required: true, message: 'Введите основной контактный телефон' },
            ]}
          >
            <PhoneInput />
          </ProForm.Item>
        </>
      ),
    },
    // {
    //   title: 'Внешний вид',
    //   content: (
    //     <>
    //       <ProFormUploadButton
    //
    //         title={'Загрузить'}
    //         name="clinicLogo"
    //         label="Загрузите логотип клиники"
    //         max={1}
    //         fieldProps={{
    //           beforeUpload: (file) => {
    //             // Выключаем автоматическую загрузку
    //             return false;
    //           },
    //         }}
    //         icon={<UploadOutlined/>}
    //         rules={[{ required: false, message: 'Загрузите логотип клиники' }]}
    //       />
    //       <ProFormUploadButton
    //         title={'Загрузить'}
    //         action={undefined}
    //         name="favicon"
    //         label="Загрузите favicon клиники"
    //         max={1}
    //         fieldProps={{
    //           beforeUpload: (file) => {
    //             // Выключаем автоматическую загрузку
    //             return false;
    //           },
    //         }}
    //         icon={<UploadOutlined/>}
    //         rules={[{ required: false, message: 'Загрузите favicon клиники' }]}
    //       />
    //       <ProForm.Item
    //         name="mainColor"
    //         label="Основной цвет"
    //         rules={[{ required: false, message: 'Выберите основной цвет' }]}
    //       >
    //         <ColorPicker
    //           mode={'single'}
    //           format={'hex'}
    //           onChange={(value) => {form.setFieldsValue({ mainColor: value.toHex() });}}
    //         />
    //       </ProForm.Item>
    //       <ProForm.Item
    //         name="secondaryColor"
    //         label="Второстепенный цвет"
    //         rules={[{ required: false, message: 'Выберите второстепенный цвет' }]}>
    //         <ColorPicker
    //           mode={'single'}
    //           format={'hex'}
    //           onChange={(value) => {form.setFieldsValue({ secondaryColor: value.toHex() });}}
    //         />
    //       </ProForm.Item>
    //     </>
    //   ),
    // },
    {
      title: 'Разделы сайта',
      content: (
        <ProFormSelect
          name="sections"
          label="Выберите разделы, которые вам необходимы на сайте"
          options={[
            { label: 'Услуги клиники', value: 'posts' },
            { label: 'Фотоальбом', value: 'albums' },
            { label: 'Новости ', value: 'news' },
            { label: 'Цены', value: 'prices' },
            { label: 'Врачи', value: 'staff' },
            { label: 'Отзывы', value: 'reviews' },
            { label: 'Портфолио', value: 'works' },
            { label: 'Акции клиники', value: 'courses' },
          ]}
          mode="multiple"
          placeholder="Выберите разделы"
          rules={[{ required: true, message: 'Выберите хотя бы один раздел' }]}
        />
      ),
    },
  ];
  // END FORM

  const next = async () => {
    try {
      await form.validateFields();
      if (steps[currentStep]?.id=='selectDomain') {
        const result= await Client.domain.validateSubdomain({slug:form.getFieldValue('slug')})
        if(result.data==false){
          form.setFields([
            {
              name: 'slug',
              errors: ['Этот адрес недоступен. Попробуйте выбрать другой.'],
            },
          ]);
          return;
        }
      }
      setData({ ...data, ...form.getFieldsValue() });

      setCurrentStep(currentStep + 1);
    } catch {
      message.error('Пожалуйста, исправьте ошибки на этом шаге');
    }

  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onFinish = async (values: CreateSiteDto) => {
    message.success('Данные успешно отправлены');

    props.onSubmit?.({ ...data, ...values });
    // Здесь можно сделать запрос на сервер для сохранения данных
  };

  return (
    <div>
      <Title level={4} style={{ color: '#3b9d9b', fontWeight: 500, marginBottom: 30 }}>
        {steps[currentStep].title}
      </Title>

      <Steps current={currentStep} style={{ marginBottom: 40 }}>
        {steps.map((item, index) => (
          <Step key={item.title}/>
        ))}
      </Steps>
      <ProForm<any>
        formRef={props?.formRef}
        form={form}
        onFinish={onFinish}
        initialValues={{}}
        submitter={{
          render: () => (
            <div style={{ marginTop: 24 }}>
              {currentStep < steps.length - 1 && (
                <Button type="primary" onClick={next}>
                  Далее
                </Button>
              )}
              {currentStep === steps.length - 1 && (
                <Button type="primary" htmlType="submit">
                  Завершить
                </Button>
              )}
              {currentStep > 0 && (
                <Button style={{ margin: '0 8px' }} onClick={prev}>
                  Назад
                </Button>
              )}
            </div>
          ),
        }}
      >
        {steps[currentStep].content}
      </ProForm>
    </div>
  );
};

export default ClinicForm;
