import DashboardPage from './pages/DashboardPage';
import { SitesPage } from './pages/SitesPage';
import { UsersPage } from './pages/UsersPage';
import i18n from 'i18next';

export const routes=[
  {
    path: '/dashboard',
    name: i18n.t('routes.dashboard'),
    component: DashboardPage,
  },
  {
    path: '/sites',
    name: i18n.t('routes.sites'),
    component: SitesPage,
  },
  {
    path: '/users',
    name: i18n.t('routes.users'),
    component: UsersPage,
  }
];
