import { Layout, message, Spin } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Title from 'antd/es/typography/Title';
import ClinicForm from '../components/form/clinic.form';
import { createRef, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Client } from '../services/Client';
import { ContentType, SessionInfoDto } from '../services/Api';
import EmailVerificationForm from '../components/form/email-verification.form';
import { useFormSubmit } from '../hooks/use-form-submit';
import { ProFormInstance } from '@ant-design/pro-form';

export const CreateSitePage = () => {

  const formRef = useRef(null);
  const { sessionId } = useParams();
  const [sessionInfo, setSessionInfo] = useState<SessionInfoDto>();
  const [loading, setLoading] = useState<boolean>(true);
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [siteCreate, siteCreateStatus] = useFormSubmit(Client.site.create, formRef);

  const getMedflexSession = async (sessionId: string) => {
    try {
      const result = await Client.medflex.getSession(sessionId);
      setSessionInfo(result.data);
      setLoading(false);
    } catch (e) {

      message.error(
        <>
          Ошибка авторизации, обратитесь в службу поддержки
          <p>
            <a href={'mailto://support@clinilink.ru'}>support@clinilink.ru</a>
          </p>

        </>, 0);
    }

  };

  useEffect(() => {

    if(siteCreateStatus=='error'){
      setLoading(false);
    }
  }, [siteCreateStatus]);

  useEffect(() => {
    getMedflexSession(sessionId!);
  }, []);

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f7f9fb' }}>


      <Content style={{ padding: '60px 40px' }}>
        <div
          style={{
            background: '#ffffff',
            padding: 40,
            borderRadius: 20,
            boxShadow: '0 12px 30px rgba(0, 0, 0, 0.05)',
            minHeight: '70vh',
            maxWidth: '800px',
            margin: '0 auto',
            border: '1px solid #e0e0e0',
            transition: 'all 0.3s ease',
          }}
        >
          <Spin spinning={loading}>
            {(emailValid || sessionInfo?.confirmed) ? (
              <ClinicForm
                formRef={formRef}
                sessionInfo={sessionInfo}
                onSubmit={async data => {
                  setLoading(true);
                  data.sessionId=sessionId||'';
                  await siteCreate(data);

                }}
              />

            ) : <EmailVerificationForm
              sessionId={sessionId!}
              onSuccess={() => setEmailValid(true)}
            />}

          </Spin>
        </div>
      </Content>
    </Layout>
  );
};
