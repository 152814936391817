import React, { useState } from 'react';
import { Form, Input, Button, Card, Typography, message } from 'antd';
import { useAuth } from '../components/auth-provider';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import logo from '../assets/images/logo.png'
const { Title } = Typography;

interface LoginValues {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();

  const onFinish = async (values: LoginValues) => {
    setLoading(true);
    try {
      await login(values);
      message.success('Login successful!');
    } catch (error) {
      message.error('Login failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)',
      }}
    >
      <Card
        style={{
          width: 350,
          borderRadius: 10,
          boxShadow: '0 10px 20px rgba(0, 0, 0, 0.12)',
        }}
      >
        <div style={{ textAlign: 'center', marginBottom: 20 }}>
          <img
            src={logo} // Replace with your logo URL or path
            alt="Logo"
            style={{ width: 60, marginBottom: 20 }}
          />
          <Title level={3} style={{ marginBottom: 20 }}>
            CliniLink
          </Title>
        </div>
        <Form
          name="login"
          onFinish={onFinish}
          layout="vertical"
          size="large"
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Введите свой email!' }]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="Email"
              style={{ borderRadius: 6 }}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Введите пароль!' }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Пароль"
              style={{ borderRadius: 6 }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              block
              style={{ borderRadius: 6 }}
            >
              Войти
            </Button>
          </Form.Item>
        </Form>
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Typography.Text type="secondary">
            Проблема с аккаунтом? <a href="mailto:support@clinilink.ru">Поддержка</a>
          </Typography.Text>
        </div>
      </Card>
    </div>
  );
};

export default LoginPage;
