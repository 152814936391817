import React, { useState } from 'react';
import { Button, Input, message, Space } from 'antd';
import { ProForm, ProFormText } from '@ant-design/pro-form';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { Client } from '../../services/Client';

interface EmailVerificationFormProps {
  sessionId: string;
  onSuccess: () => void;
}

const EmailVerificationForm = (props: EmailVerificationFormProps) => {
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [otp, setOtp] = useState<string>('');

  // Функция для отправки OTP на email
  const sendOtp = async (email: string) => {
    try {
      setLoading(true);
      // Замените на URL вашего API, который отправляет OTP
      const result = await Client.site.sendCode({ email, sessionId: props.sessionId });
      if (result.status === 200) {
        message.success('OTP отправлен на ваш email');
        setOtpSent(true);
      }
    } catch (error) {
      message.error('Ошибка при отправке OTP');
    } finally {
      setLoading(false);
    }
  };

  // Функция для проверки OTP
  const verifyOtp = async () => {
    try {
      setLoading(true);
      // Замените на URL вашего API для проверки OTP
      const response = await Client.site.verificationCode({ code: otp, sessionId: props.sessionId });
      if (response.status === 200) {
        message.success('Email подтвержден');
        props.onSuccess();
      }
    } catch (error) {
      message.error('Неверный OTP');
    } finally {
      setLoading(false);
    }
  };

  // Проверка на валидность email перед отправкой OTP
  const handleSendOtp = () => {
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      message.error('Пожалуйста, введите корректный email');
      return;
    }
    sendOtp(email);
  };

  return (
    <>
      <Title level={4}>Проверка Email</Title>

      <Paragraph style={{ fontSize: '16px' }}>

        Для обеспечения безопасности и точности вашей регистрации, мы просим вас подтвердить ваш email-адрес. Мы отправим одноразовый пароль (OTP) на указанный
        вами email. Пожалуйста, проверьте вашу почту (и папку "Спам", если нужно) на наличие OTP, затем введите его ниже для подтверждения вашего email-адреса.

        Этот шаг помогает нам удостовериться, что указанный email действителен и принадлежит вам, чтобы мы могли безопасно отправлять вам важные обновления и
        уведомления
      </Paragraph>
      <ProForm
        style={{ width: 300, margin: '0 auto' }}
        onFinish={() => {}}
        submitter={{
          render: (props) => (
            <Space direction="vertical" style={{ width: '100%' }}>
              <Button
                size="large"
                type="primary"
                loading={loading}
                onClick={handleSendOtp}
                disabled={otpSent}
              >
                Отправить код проверки
              </Button>

              {otpSent && (
                <ProForm.Item label={'Введите код полученный в письме'}>
                  <Input.OTP
                    size="large"
                    value={otp}
                    onChange={(value) => {
                      setOtp(value);
                    }}
                  />
                </ProForm.Item>

              )}

              {otpSent && (
                <Button
                  size="large"
                  type="primary"
                  loading={loading}
                  onClick={verifyOtp}
                  disabled={otp.length != 6}
                >
                  Подтвердить
                </Button>
              )}
            </Space>
          ),
        }}
      >
        <ProFormText
          name="email"
          label="Введите ваш рабочий Email"
          fieldProps={{
            size: 'large',
            value: email,
            onChange: (e: any) => setEmail(e.target.value),
          }}
          placeholder="Введите ваш email"
          rules={[
            { required: true, message: 'Пожалуйста, введите ваш email' },
            { type: 'email', message: 'Пожалуйста, введите корректный email' },
          ]}
        />
      </ProForm>
    </>

  );
};

export default EmailVerificationForm;
